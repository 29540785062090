import Vue from 'vue'
import vueFilePond from 'vue-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
// Create component
const FilePond = vueFilePond(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginFileValidateType
);

Vue.component('file-pond', FilePond);

window.filepondServer = {
    url: null,
    process: '/api/process',
    revert: '/api/process',
    restore: null,
    load: null,
    fetch: null
};