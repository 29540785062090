import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
    faCheckCircle,
    faExclamation,
    faBars,
    faUserCog,
    faDollarSign,
    faTags,
    faBell,
    faUsers,
    faUserFriends,
    faSignOutAlt,
    faIdCard,
    faTrash,
    faFileUpload,
    faCheck,
    faTimes,
    faPrint,
    faMapMarkerAlt,
    faClock,
    faPlus,
    faHome,
    faCog,
    faEdit,
    faTools,
    faClipboardList,
    faWrench,
    faSave,
    faDownload,
    faAd,
    faBuilding,
    faFileContract,
    faFileInvoice
} from '@fortawesome/free-solid-svg-icons'

import {
    faUser,
    // faBuilding
} from '@fortawesome/free-regular-svg-icons'

import {
    faInstagram,
    faYoutube,
    faTwitter,
} from '@fortawesome/free-brands-svg-icons'

library.add(
    faCheckCircle,
    faExclamation,
    faInstagram,
    faYoutube,
    faTwitter,
    faBars,
    faUser,
    faUserCog,
    faDollarSign,
    faTags,
    faBell,
    faUsers,
    faUserFriends,
    faSignOutAlt,
    faIdCard,
    faTrash,
    faFileUpload,
    faCheck,
    faTimes,
    faPrint,
    faMapMarkerAlt,
    faClock,
    faPlus,
    faHome,
    faCog,
    faEdit,
    faTools,
    faClipboardList,
    faWrench,
    faSave,
    faDownload,
    faAd,
    faBuilding,
    faFileContract,
    faFileInvoice
)

Vue.component('fa', FontAwesomeIcon)