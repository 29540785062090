<template>
  <div class="container overview">
    <div class="row">
      <div class="col-12 col-sm-6">
        <div class="item">
          <div class="title">العملاء</div>
          <div class="content">
            <ArabicNumber :number="settings.clientsCount + 600" />
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-6">
        <div class="item">
          <div class="title">الموظفون</div>
          <div class="content">
            <ArabicNumber :number="settings.agentsCount" />
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-6">
        <div class="item">
          <div class="title">العقارات</div>
          <div class="content">
            <ArabicNumber :number="settings.propertiesCount" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SettingsMixin from '@/mixins/settings'

export default {
  mixins: [SettingsMixin]
};
</script>