<template>
  <li>
    <a href="" @click.prevent="editBillTap">
      <div class="row">
        <div class="col-12 col-sm-6 col-md-6 panel-info text-center text-sm-right">
          <div @click.stop class="d-inline">
            <b-form-checkbox
              class="d-inline pl-0"
              v-model="bill.selected"
              :value="true"
              @change.native="toggleBillSelection(bill)"
            >
            </b-form-checkbox>
          </div>
          <!-- <div v-else class="d-inline-block" style="width: 24px"></div> -->
          <!-- <span class="text-muted small">الفاتورة: </span> -->
          {{ bill.body }}
        </div>

        <div class="col-12 col-sm-6 col-md-4 panel-info text-center">
          {{ getDate(bill.updated_at) }}
        </div>

        <div class="col-12 col-sm-6 col-md-2 panel-info text-center text-sm-left" @click.prevent>
          <!-- <button class="btn btn-info btn-sm" @click.prevent="editBillTap">
            <fa icon="edit" />
          </button> -->
        </div>
      </div>

      <div
        v-if="showDetails"
        @click.stop="showDetails = false"
        class="container doc-row-details"
      >
        <div class="row">
          <div class="col-12 text-justify">
            {{ bill.body }}
          </div>
        </div>
      </div>
    </a>
  </li>
</template>

<script>
import BillsMixin from '@/mixins/bills'

export default {
  mixins: [BillsMixin],

  data() {
    return {
      showDetails: false,
    };
  },

  methods: {
    editBillTap () {
      this.selectBill(this.bill)
      this.$bvModal.show('bill-modal')
    },
  },

  props: ["bill"],
};
</script>