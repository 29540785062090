import { mapGetters, mapActions } from 'vuex';

export default {
    methods: {
        ...mapActions({
            verifyEmail: 'auth/verifyEmail',
            sendPasswordResetLink: 'auth/sendPasswordResetLink',
            verifyPasswordResetToken: 'auth/verifyPasswordResetToken',
            resetPassword: 'auth/resetPassword',
            logout: 'auth/logout',
            updateAdminData: 'auth/updateAdminData'
        })
    },

    computed: {
        ...mapGetters({
            user: 'auth/user',
            loggedIn: 'auth/loggedIn'
        })
    }
}