<template>
  <div>
    <div class="container clearfix">
      <div class="form-group float-right mt-3">
        <input
          type="text"
          class="form-control"
          placeholder="البحث"
          v-model="searchTerm"
        />
      </div>
    </div>
    <PanelList
      :items="finalLeaseRequests"
      title="طلبات العقود"
      :isAllSelected="isAllSelected"
      @toggle-items-selection="toggleLeaseRequestsSelection()"
      @delete-selected="deleteSelectedLeaseRequests()"
    >
      <LeaseRequestPanelItem
        v-for="(leaseRequest, i) in finalLeaseRequests"
        :key="i"
        :leaseRequest="leaseRequest"
      />
    </PanelList>

    <ResidentialLeaseModal />
    <CommercialLeaseModal />
    <SubLeaseModal />
  </div>
</template>

<script>
import PanelList from "@/components/general/panel-list";
import LeaseRequestPanelItem from "@/components/panel-items/leaseRequest";
import LeaseRequestsMixin from "@/mixins/leaseRequests";
import ResidentialLeaseModal from "@/components/modals/residential-lease";
import CommercialLeaseModal from "@/components/modals/commercial-lease";
import SubLeaseModal from "@/components/modals/sub-lease";

export default {
  mixins: [LeaseRequestsMixin],

  data() {
    return {
      searchTerm: "",
    };
  },

  mounted () {
    this.clearLeaseRequestsCounter()
  },

  computed: {
    isAllSelected() {
      return this.selectedLeaseRequests.length == this.leaseRequests.length;
    },

    finalLeaseRequests() {
      if (!this.searchTerm) {
        return this.leaseRequests;
      }

      return this.leaseRequests.filter((r) => r.agent.name.indexOf(this.searchTerm) > -1);
    },
  },

  components: {
    PanelList,
    LeaseRequestPanelItem,
    ResidentialLeaseModal,
    CommercialLeaseModal,
    SubLeaseModal,
  },
};
</script>