<template>
  <b-modal id="residential-lease-modal" title="العقد السكني" size="lg">
    <div v-if="selectedLeaseRequest" class="container py-2">
      <div class="form-group row">
        <label for="nameAr" class="col-md-4 col-form-label text-md-right"
          >حدد الموظف</label
        >

        <div class="col-md-8">
          <multiselect
            dir="rtl"
            class="text-right"
            v-model="selectedLeaseRequest.agent"
            :options="agents"
            placeholder="حدد الموظف"
            label="name"
            track-by="name"
            selectLabel="اضغظ Enter للتحديد"
            deselectLabel="اضغط Enter لإلغاء التحديد"
            autofocus
          >
            <template><span slot="noResult">لا توجد نتائج</span></template>
            <template><span slot="noOptions">لا توجد خيارات</span></template>
          </multiselect>
        </div>
      </div>

      <fieldset class="border p-2 mb-3">
        <legend class="w-auto bold" style="font-size: 16px">
          بيانات المؤجر
        </legend>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >رقم الهوية</label
          >

          <div class="col-md-8">
            <input
              type="number"
              class="form-control"
              v-model="selectedLeaseRequest.renterIdNumber"
              required
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >الاسم كاملًا</label
          >

          <div class="col-md-8">
            <input
              type="text"
              class="form-control"
              v-model="selectedLeaseRequest.renterName"
              required
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >الجوال المسجل بأبشر</label
          >

          <div class="col-md-8">
            <input
              type="number"
              class="form-control"
              v-model="selectedLeaseRequest.renterMobile"
              required
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >تاريخ الميلاد</label
          >

          <div class="col-md-8">
            <div class="row">
              <div class="col-4">
                <input
                  type="text"
                  class="form-control"
                  v-model="selectedLeaseRequest.renterBirthDay"
                  placeholder="يوم"
                />
              </div>

              <div class="col-4">
                <input
                  type="text"
                  class="form-control"
                  v-model="selectedLeaseRequest.renterBirthMonth"
                  placeholder="شهر"
                />
              </div>

              <div class="col-4">
                <input
                  type="text"
                  class="form-control"
                  v-model="selectedLeaseRequest.renterBirthYear"
                  placeholder="سنة"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >عدد الشقق</label
          >

          <div class="col-md-8">
            <input
              type="number"
              class="form-control"
              v-model="selectedLeaseRequest.renterApartmentsCount"
              required
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >عدد الغرف</label
          >

          <div class="col-md-8">
            <input
              type="number"
              class="form-control"
              v-model="selectedLeaseRequest.renterRoomsCount"
              required
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >عدد الصالات</label
          >

          <div class="col-md-8">
            <input
              type="number"
              class="form-control"
              v-model="selectedLeaseRequest.renterHallsCount"
              required
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >عدد الحمامات</label
          >

          <div class="col-md-8">
            <input
              type="number"
              class="form-control"
              v-model="selectedLeaseRequest.renterBathroomsCount"
              required
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >عدد المكيفات</label
          >

          <div class="col-md-8">
            <input
              type="number"
              class="form-control"
              v-model="selectedLeaseRequest.renterACCount"
              required
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >مفروش/غير مفروش</label
          >

          <div class="col-md-8">
            <select class="form-control" v-model="selectedLeaseRequest.renterIsFurnished">
              <option value="مفروش">مفروش</option>
              <option value="غير مفروش">غير مفروش</option>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >نوع عداد الكهرباء</label
          >

          <div class="col-md-8">
            <select
              class="form-control"
              v-model="selectedLeaseRequest.renterElectricType"
            >
              <option value="مستقل">مستقل</option>
              <option value="مشترك">مشترك</option>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >رقم حساب عداد الكهرباء</label
          >

          <div class="col-md-8">
            <input
              type="text"
              class="form-control"
              v-model="selectedLeaseRequest.renterElectricMeterNumber"
              required
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >تاريخ بداية العقد</label
          >

          <div class="col-md-8">
            <div class="row">
              <div class="col-4">
                <input
                  type="text"
                  class="form-control"
                  v-model="selectedLeaseRequest.renterLeaseStartDay"
                  placeholder="يوم"
                />
              </div>

              <div class="col-4">
                <input
                  type="text"
                  class="form-control"
                  v-model="selectedLeaseRequest.renterLeaseStartMonth"
                  placeholder="شهر"
                />
              </div>

              <div class="col-4">
                <input
                  type="text"
                  class="form-control"
                  v-model="selectedLeaseRequest.renterLeaseStartYear"
                  placeholder="سنة"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >مبلغ الإيجار السنوي</label
          >

          <div class="col-md-8">
            <input
              type="number"
              class="form-control"
              v-model="selectedLeaseRequest.renterLeaseYearlyAmount"
              required
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >نوع الدفعات</label
          >

          <div class="col-md-8">
            <select
              class="form-control"
              v-model="selectedLeaseRequest.renterLeasePaymentPeriod"
            >
              <option value="شهري">شهري</option>
              <option value="ربع سنوي">ربع سنوي</option>
              <option value="نصف سنوي">نصف سنوي</option>
              <option value="سنوي">سنوي</option>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >الآيبان البنكي</label
          >

          <div class="col-md-8">
            <input
              type="text"
              class="form-control"
              v-model="selectedLeaseRequest.renterIban"
              required
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >صورة واضحة للصك</label
          >

          <div class="col-md-8">
            <div
              class="pointer d-inline-block mt-3"
              @click="$refs.leaseImage.click()"
            >
              <div class="m-auto">
                <img
                  ref="leasePreview"
                  class="img-preview m-auto"
                  :src="getLeaseImage"
                  alt=""
                />
              </div>
              <span class="text-muted small">تحديد الصورة</span>
              <input
                ref="leaseImage"
                type="file"
                style="display: none"
                @change="updateLeaseImage"
                accept="image/*"
              />
            </div>
          </div>
        </div>

        <fieldset class="border p-2 mb-3">
          <legend class="w-auto" style="font-size: 16px">العنوان الوطني</legend>

          <div class="form-group row">
            <label for="nameAr" class="col-md-4 col-form-label text-md-right"
              >رقم المبنى</label
            >

            <div class="col-md-8">
              <input
                type="text"
                required
                class="form-control"
                v-model="selectedLeaseRequest.renterBuildingNumber"
              />
            </div>
          </div>

          <div class="form-group row">
            <label for="nameAr" class="col-md-4 col-form-label text-md-right"
              >الرقم الإضافي</label
            >

            <div class="col-md-8">
              <input
                type="text"
                required
                class="form-control"
                v-model="selectedLeaseRequest.renterAdditionalNumber"
              />
            </div>
          </div>

          <div class="form-group row">
            <label for="nameAr" class="col-md-4 col-form-label text-md-right"
              >الرمز البريدي</label
            >

            <div class="col-md-8">
              <input
                type="text"
                required
                class="form-control"
                v-model="selectedLeaseRequest.renterPostalCode"
              />
            </div>
          </div>

          <div class="form-group row">
            <label for="nameAr" class="col-md-4 col-form-label text-md-right"
              >الموقع على الخريطة</label
            >

            <div class="col-md-8">
              <GmapMap
                ref="map"
                :center="{
                  lat: selectedLeaseRequest.renterLat,
                  lng: selectedLeaseRequest.renterLng,
                }"
                :zoom="14"
                style="width: 100%; height: 300px"
                @click="updateMarker"
              >
                <GmapMarker
                  :position="{
                    lat: selectedLeaseRequest.renterLat,
                    lng: selectedLeaseRequest.renterLng,
                  }"
                  :clickable="false"
                  :draggable="false"
                  @click="
                    center = {
                      lat: selectedLeaseRequest.renterLat,
                      lng: selectedLeaseRequest.renterLng,
                    }
                  "
                />
              </GmapMap>
            </div>
          </div>
        </fieldset>
      </fieldset>

      <fieldset class="border p-2 my-5">
        <legend class="w-auto bold" style="font-size: 16px">
          بيانات المستأجر
        </legend>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >رقم الهوية</label
          >

          <div class="col-md-8">
            <input
              type="number"
              class="form-control"
              v-model="selectedLeaseRequest.residentIdNumber"
              required
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >الاسم كاملًا</label
          >

          <div class="col-md-8">
            <input
              type="text"
              class="form-control"
              v-model="selectedLeaseRequest.residentName"
              required
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >الجوال المسجل بأبشر</label
          >

          <div class="col-md-8">
            <input
              type="number"
              class="form-control"
              v-model="selectedLeaseRequest.residentMobile"
              required
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >تاريخ الميلاد</label
          >

          <div class="col-md-8">
            <div class="row">
              <div class="col-4">
                <input
                  type="text"
                  class="form-control"
                  v-model="selectedLeaseRequest.residentBirthDay"
                  placeholder="يوم"
                />
              </div>

              <div class="col-4">
                <input
                  type="text"
                  class="form-control"
                  v-model="selectedLeaseRequest.residentBirthMonth"
                  placeholder="شهر"
                />
              </div>

              <div class="col-4">
                <input
                  type="text"
                  class="form-control"
                  v-model="selectedLeaseRequest.residentBirthYear"
                  placeholder="سنة"
                />
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </div>

    <template v-slot:modal-footer>
      <div class="w-100">
        <div class="float-right">
          <b-button
            variant="primary"
            size="md"
            style="position: relative"
            @click="submit"
          >
            <i class="fa fa-check"></i>
            <span>حفظ</span>
          </b-button>
        </div>
        <button
          @click="$bvModal.hide('residential-lease-modal')"
          class="float-left btn btn-light"
          style="box-shadow: none"
        >
          تراجع
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import RequestsMixin from "@/mixins/requests";
import AgentsMixin from "@/mixins/agents";
import LeaseRequestMixin from "@/mixins/leaseRequests";

export default {
  mixins: [RequestsMixin, AgentsMixin, LeaseRequestMixin],

  data() {
    return {
      leaseImage: null,
    };
  },

  mounted() {
    this.selectedLeaseRequest.agent = this.agents.find(
      (a) => a.id == this.selectedLeaseRequest.agent.id
    );
  },

  methods: {
    submit() {
      if (!this.selectedLeaseRequest.agent) {
        this.showSwal({
          success: false,
          message: "فضلًا قم بتحديد الموظف المطلوب",
        });
      }

      this.selectedLeaseRequest.leaseImage = this.leaseImage;
      this.updateLeaseRequest().then((res) => {
        if (res.success) this.$bvModal.hide("residential-lease-modal");
      });
    },

    updateLeaseImage(e) {
      this.$refs.leasePreview.src = URL.createObjectURL(e.target.files[0]);
      this.leaseImage = e.target.files[0];
    },

    updateMarker(e) {
      this.selectedLeaseRequest.renterLat = e.latLng.lat();
      this.selectedLeaseRequest.renterLng = e.latLng.lng();
    },
  },

  computed: {
    getLeaseImage() {
      return this.selectedLeaseRequest.leaseImageLink || require("@/assets/images/image-placeholder.png");
    },
  },
};
</script>