import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
    methods: {
        ...mapActions({
            saveClient: 'clients/saveClient',
            saveClientImage: 'clients/saveClientImage',
            toggleClientsSelection: 'clients/toggleClientsSelection',
            toggleClientSelection: 'clients/toggleClientSelection',
            deleteSelectedClients: 'clients/deleteSelectedClients',
            exportClients: 'clients/exportClients'
        }),

        ...mapMutations({
            selectClient: 'clients/SET_SELECTED_CLIENT',
            clientsSetSearchTerm: 'clients/SET_SEARCH_TERM'
        })
    },

    computed: {
        ...mapGetters({
            clients: 'clients/clients',
            selectedClient: 'clients/selectedClient',
            selectedClients: 'clients/selectedClients',
            clientsSearchTerm: 'clients/searchTerm',
            clientsFilter: 'clients/clientsFilter'
        })
    }
}