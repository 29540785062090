<template>
  <div class="">
    <div class="w-50 m-auto text-center">
      <div class="row">
        <div class="col text-left text-muted p-2 d-none d-sm-block">
          <span> الكود: </span>
        </div>
        <div class="col text-right">
          <div class="row">
            <div class="col-8 col-md-10">
              <input
                type="text"
                class="form-control"
                v-model="settings.discountCode"
              />
            </div>
            <div class="col-4 col-md-2">
              <button
                class="btn btn-sm btn-primary"
                style="position: relative; top: 5px"
                @click="updateSettings"
              >
                <fa icon="check" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="text-right my-3">
      <button class="btn btn-sm btn-primary" @click="addDiscountTap" title="إضافة مقدم خصم">
        <fa icon="plus" class="ml-0 ml-sm-2" />
        <span class="d-none d-sm-inline-block">إضافة مقدم خصم</span>
      </button>
    </div>

    <PanelList
      :items="discounts"
      title="الخصومات"
      :isAllSelected="isAllSelected"
      @toggle-items-selection="toggleDiscountsSelection()"
      @delete-selected="deleteSelectedDiscounts()"
    >
      <DiscountPanelItem
        v-for="(discount, i) in discounts"
        :key="i"
        :discount="discount"
      />
    </PanelList>

    <DiscountModal />
  </div>
</template>

<script>
import PanelList from "@/components/general/panel-list";
import DiscountPanelItem from "@/components/panel-items/discount";
import DiscountModal from "@/components/modals/discount";
import SettingsMixin from "@/mixins/settings";
import DiscountsMixin from "@/mixins/discounts";

export default {
  mixins: [SettingsMixin, DiscountsMixin],

  methods: {
    addDiscountTap () {
      this.selectDiscount(null)
      this.$bvModal.show('discount-modal')
    }
  },

  computed: {
    isAllSelected () {
      return this.discounts.length == this.selectedDiscounts.length
    }
  },

  components: {
    PanelList,
    DiscountPanelItem,
    DiscountModal,
  },
};
</script>