<template>
  <span>
    <span v-if="lang == 'ar'">{{ ar }}</span>
    <span v-if="lang == 'en'">{{ en }}</span>
  </span>
</template>

<script>
import Translations from '@/translations.json'
export default {
    data () {
        return {
            lang: this.$store.getters.lang
        }
    },

    computed: {
        ar () {
            return Translations.ar[this.phrase]
        },
        
        en () {
            return Translations.en[this.phrase]
        }
    },

    props: ['phrase']
}
</script>