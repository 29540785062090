import axios from '@/axios'

export default {
    namespaced: true,

    state: {
        agreements: [],
        selectedAgreement: null
    },

    getters: {
        agreements: state => state.agreements,
        selectedAgreement: state => state.selectedAgreement
    },

    mutations: {
        SET_AGREEMENTS: (state, payload) => state.agreements = payload,
        SELECT_AGREEMENT: (state, payload) => state.selectedAgreement = payload
    },

    actions: {
        async fetchAgreements({ commit }) {
            let { data } = await axios.get('admin/agreements')

            commit('SET_AGREEMENTS', data.agreements)
        },

        selectAgreement({ commit, state, rootState }, propertyId) {
            let property = rootState.properties.properties.find(p => p.id == propertyId)
            let agreement = state.agreements.find(a => {
                return (a.property_id == propertyId && a.user_id == property.client_id)
            })

            commit('SELECT_AGREEMENT', agreement)
        },

        selectAgreementByClientId({ commit, state, rootState }, clientId) {
            let property = rootState.properties.properties.find(p => p.client_id == clientId)
            let agreement = state.agreements.find(a => {
                return (a.property_id == property.id && a.user_id == property.client_id)
            })
            commit('SELECT_AGREEMENT', agreement)
        },

        async updateAgreement({ dispatch, getters }) {
            let { data } = await axios.put(`admin/agreements/${getters.selectedAgreement.id}`, getters.selectedAgreement)

            if (data.success) {
                dispatch('properties/fetchProperties', {}, { root: true })
                dispatch('fetchAgreements')
            }

            return data
        }
    }
}