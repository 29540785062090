<template>
  <div>
    <div class="text-right mb-3">
      <button
        class="btn btn-primary btn-sm"
        @click="addAgentTap"
        title="إضافة موظف"
      >
        <fa icon="plus" class="ml-0 ml-sm-2" />
        <span class="d-none d-sm-inline-block">إضافة موظف</span>
      </button>

      <button
        class="btn btn-primary btn-sm mr-3"
        @click="saveNotificationTap"
        title="إرسال إشعار"
      >
        <fa icon="bell" class="ml-0 ml-sm-2" />
        <span class="d-none d-sm-inline-block">إرسال إشعار</span>
      </button>

      <button
        class="btn btn-primary btn-sm mr-3 float-left"
        @click="exportAgentsTap"
        title="تنزيل"
      >
        <fa icon="download" class="ml-0 ml-sm-2" />
        <span class="d-none d-sm-inline-block">تنزيل</span>
      </button>
    </div>

    <div class="container clearfix">
      <div class="form-group float-right mt-3">
        <input
          type="text"
          class="form-control"
          placeholder="البحث"
          v-model="searchTerm"
        />
      </div>
    </div>

    <PanelList
      :items="finalAgents"
      title="الموظفون"
      :isAllSelected="isAllSelected"
      @toggle-items-selection="toggleAgentsSelection()"
      @delete-selected="deleteSelectedAgents()"
      :canSelectAll="false"
    >
      <AgentPanelItem v-for="(agent, i) in finalAgents" :key="i" :agent="agent" />
    </PanelList>

    <AgentModal />
    <NotificationModal />
  </div>
</template>

<script>
import PanelList from "@/components/general/panel-list";
import AgentPanelItem from "@/components/panel-items/agent";
import AgentModal from "@/components/modals/agent";
import AgentsMixin from "@/mixins/agents";
import ClientsMixin from "@/mixins/clients";
import NotificationModal from "@/components/modals/notification";
import HashmanagerMixin from '@/mixins/hashmanager'

export default {
  mixins: [AgentsMixin, ClientsMixin, HashmanagerMixin],

  data () {
    return {
      searchTerm: ''
    }
  },

  methods: {
    addAgentTap() {
      this.selectAgent(null);
      this.$bvModal.show("agent-modal");
    },

    saveNotificationTap() {
      this.selectAgent(null);
      this.selectClient(null);
      this.$bvModal.show("notification-modal");
    },

    exportAgentsTap() {
      this.storeHash().then((res) => {
        if (res.success) {
          window.open(
            `${this.VUE_APP_BACKEND_WEB_URL}/exportAgents/${res.hash}`
          );
        }
      });
    },
  },

  computed: {
    isAllSelected() {
      return this.agents.length == this.selectedAgents.length;
    },

    finalAgents () {
      if (!this.searchTerm) {
        return this.agents
      }

      return this.agents.filter(a => a.name.indexOf(this.searchTerm) > -1)
    }
  },

  components: {
    PanelList,
    AgentPanelItem,
    AgentModal,
    NotificationModal,
  },
};
</script>