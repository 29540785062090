<template>
  <div>
      <div class="text-right">
          <button class="btn btn-primary btn-sm" @click="addMaintenanceTap">
              <fa icon="plus" class="ml-2" />
              <span>إضافة طلب</span>
          </button>

          <button class="btn btn-primary btn-sm mr-3 float-left" @click="exportMaintenancesTap">
              <fa icon="download" class="ml-2" />
              <span>تنزيل</span>
          </button>
      </div>
    <PanelList
      class="mt-3"
      :items="maintenances"
      title="طلبات العملاء"
      :isAllSelected="isAllSelected"
      @toggle-items-selection="toggleMaintenancesSelection()"
      @delete-selected="deleteSelectedMaintenances()"
    >
      <MaintenancePanelItem
        v-for="(maintenance, i) in maintenances"
        :key="i"
        :maintenance="maintenance"
      />
    </PanelList>

    <MaintenanceModal />
  </div>
</template>

<script>
import PanelList from "@/components/general/panel-list";
import MaintenancePanelItem from "@/components/panel-items/maintenance";
import MaintenanceModal from "@/components/modals/maintenance";
import MaintenancesMixin from '@/mixins/maintenances'
import PropertiesMixin from '@/mixins/properties'
import HashmanagerMixin from '@/mixins/hashmanager'

export default {
  mixins: [MaintenancesMixin, PropertiesMixin, HashmanagerMixin],

  mounted () {
    this.clearMaintenancesCounter()
  },

  methods: {
    addMaintenanceTap () {
      this.selectMaintenance(null)
      this.selectProperty(null)
      this.$bvModal.show('maintenance-modal')
    },

    exportMaintenancesTap () {
      this.storeHash().then((res) => {
        if (res.success) {
          window.open(
            `${this.VUE_APP_BACKEND_WEB_URL}/exportMaintenances/${res.hash}`
          );
        }
      });
    }
  },

  computed: {
    isAllSelected () {
      return this.maintenances.length == this.selectedMaintenances.length
    }
  },

  components: {
    PanelList,
    MaintenancePanelItem,
    MaintenanceModal,
  },
};
</script>