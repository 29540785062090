import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
    methods: {
        ...mapActions({
            saveDiscount: 'discounts/saveDiscount',
            saveDiscountImage: 'discounts/saveDiscountImage',
            toggleDiscountsSelection: 'discounts/toggleDiscountsSelection',
            toggleDiscountSelection: 'discounts/toggleDiscountSelection',
            deleteSelectedDiscounts: 'discounts/deleteSelectedDiscounts',
        }),

        ...mapMutations({
            selectDiscount: 'discounts/SET_SELECTED_DISCOUNT',
        })
    },

    computed: {
        ...mapGetters({
            discounts: 'discounts/discounts',
            selectedDiscount: 'discounts/selectedDiscount',
            selectedDiscounts: 'discounts/selectedDiscounts',
        })
    }
}