<template>
  <li>
    <a href="" @click.prevent="editMaintenanceTap">
      <div class="row">
        <div class="col-12 col-sm-6 col-md-6 panel-info text-center text-sm-right">
          <div @click.stop class="d-inline">
            <b-form-checkbox
              class="d-inline pl-0"
              v-model="maintenance.selected"
              :value="true"
              @change.native="toggleMaintenanceSelection(maintenance)"
            >
            </b-form-checkbox>
          </div>
          <!-- <div v-else class="d-inline-block" style="width: 24px"></div> -->
          <span class="text-muted small">رقم العقار: </span>
          {{ maintenance.property.number }}
        </div>

        <div class="col-12 col-sm-6 col-md-4 panel-info text-center">
          {{ getDate(maintenance.updated_at) }}
        </div>

        <div class="col-12 col-sm-6 col-md-2 panel-info text-center text-sm-left" @click.prevent>
          <!-- <button class="btn btn-info btn-sm" @click.prevent="editMaintenanceTap">
            <fa icon="edit" />
          </button> -->
        </div>
      </div>

      <div
        v-if="showDetails"
        @click.stop="showDetails = false"
        class="container doc-row-details"
      >
        <div class="row">
          <div class="col-12 text-justify">
            {{ maintenance.body }}
          </div>
        </div>
      </div>
    </a>
  </li>
</template>

<script>
import MaintenancesMixin from '@/mixins/maintenances'

export default {
  mixins: [MaintenancesMixin],

  data() {
    return {
      showDetails: false,
    };
  },

  methods: {
    editMaintenanceTap () {
      this.selectMaintenance(this.maintenance)
      this.$bvModal.show('maintenance-modal')
    },
  },

  props: ["maintenance"],
};
</script>