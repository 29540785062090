import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
    methods: {
        ...mapActions({
            updateLeaseRequest: 'leaseRequests/updateLeaseRequest',
            toggleLeaseRequestsSelection: 'leaseRequests/toggleRecordsSelection',
            toggleLeaseRequestSelection: 'leaseRequests/toggleRecordSelection',
            deleteSelectedLeaseRequests: 'leaseRequests/deleteSelectedRecords',
            clearLeaseRequestsCounter: 'leaseRequests/clearLeaseRequestsCounter'
        }),

        ...mapMutations({
            selectLeaseRequest: 'leaseRequests/SET_SELECTED_RECORD',
        })
    },

    computed: {
        ...mapGetters({
            leaseRequests: 'leaseRequests/records',
            selectedLeaseRequest: 'leaseRequests/selectedRecord',
            selectedLeaseRequests: 'leaseRequests/selectedRecords',
        })
    }
}