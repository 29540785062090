import axios from '@/axios'

export default {
    namespaced: true,

    state: {
        agents: [],
        selectedAgent: null
    },

    getters: {
        agents: state => state.agents,
        selectedAgent: state => state.selectedAgent,
        selectedAgents: state => {
            return state.agents.filter(m => m.selected)
        },
    },

    mutations: {
        SET_AGENTS: (state, payload) => state.agents = payload,
        SET_SELECTED_AGENT: (state, payload) => state.selectedAgent = payload,
        SELECT_ALL: state => {
            state.agents.forEach(m => m.selected = true)
        },
        DESELECT_ALL: state => {
            state.agents.forEach(m => m.selected = false)
        },
        SELECT_AGENT: (state, payload) => {
            state.agents[state.agents.indexOf(payload)].selected = true
        },

        DESELECT_AGENT: (state, payload) => {
            state.agents[state.agents.indexOf(payload)].selected = false
        },
    },

    actions: {
        async fetchAgents({ commit }) {
            let { data } = await axios.get('admin/agents')
            data.agents.forEach(m => m.selected = false)
            commit('SET_AGENTS', data.agents)

        },

        toggleAgentsSelection({ commit, getters, state }) {
            if (getters.selectedAgents.length < state.agents.length) {
                commit('SELECT_ALL')
            } else {
                commit('DESELECT_ALL')
            }
        },

        toggleAgentSelection({ commit, state }, payload) {
            let currentState = state.agents[state.agents.indexOf(payload)].selected
            if (currentState) {
                commit('SELECT_AGENT', payload)
            } else {
                commit('DESELECT_AGENT', payload)
            }
        },

        async deleteSelectedAgents({ getters, dispatch }) {
            let ids = []
            getters.selectedAgents.forEach(doc => ids.push(doc.id))
            let { data } = await axios.delete('admin/agents', { params: { ids } })
            dispatch('fetchAgents')
            return data
        },

        async saveAgent({ commit, dispatch, getters }, payload) {
            let url = getters.selectedAgent ? `admin/agents/${getters.selectedAgent.id}` : `admin/agents`
            payload.role = 'agent'
            let { data } = await axios({
                method: getters.selectedAgent ? 'put' : 'post',
                url,
                data: payload
            })

            if (data.success) {
                dispatch('fetchAgents')
                commit('SET_SELECTED_AGENT', data.agent)
            }

            return data
        },

        async saveAgentImage({ getters, dispatch }, image) {
            let formData = new FormData()
            formData.append('images[0]', image)
            formData.append('user_id', getters.selectedAgent.id)
            let { data } = await axios.post('admin/images', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            })

            if (data.success) {
                dispatch('fetchAgents')
            }

            return data
        },

        async exportAgents() {
            try {
                let { data } = await axios.get('admin/agentsExport', {
                    responseType: 'arraybuffer',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/pdf'
                    }
                })

                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'الموظفون.pdf'); //or any other extension
                document.body.appendChild(link);
                link.click();

                console.log(data)

                return data
            } catch (e) {
                console.log(e)
            }
        }
    }
}