<template>
    <span>{{ finalNumber }}</span>
</template>

<script>
export default {
    methods: {
        getArabicFormat (num) {
                if (num == 0) return '٠'
                if (num == 1) return '١'
                if (num == 2) return '٢'
                if (num == 3) return '٣'
                if (num == 4) return '٤'
                if (num == 5) return '٥'
                if (num == 6) return '٦'
                if (num == 7) return '٧'
                if (num == 8) return '٨'
                if (num == 9) return '٩'
                return num
        }
    },

    computed: {
        finalNumber () {
            if (isNaN(this.number)) return '٠'
            let result = []
            let tempNumber = String(this.number).split('')
            let _this = this
            tempNumber.forEach(n => {
                result.push(_this.getArabicFormat(n))
            })
            return result.join('')
        }
    },

    props: ['number']
}
</script>