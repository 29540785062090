import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
    methods: {
        ...mapActions({
            saveBill: 'bills/saveRecord',
            toggleBillsSelection: 'bills/toggleRecordsSelection',
            toggleBillSelection: 'bills/toggleRecordSelection',
            deleteSelectedBills: 'bills/deleteSelectedRecords',
            clearBillsCounter: 'bills/clearBillsCounter'
        }),

        ...mapMutations({
            selectBill: 'bills/SET_SELECTED_RECORD',
        })
    },

    computed: {
        ...mapGetters({
            bills: 'bills/records',
            selectedBill: 'bills/selectedRecord',
            selectedBills: 'bills/selectedRecords',
        })
    }
}