<template>
  <div>
    <PanelList
      class="mt-3"
      :items="requests"
      title="طلبات الزوار"
      :isAllSelected="isAllSelected"
      @toggle-items-selection="toggleRequestsSelection()"
      @delete-selected="deleteSelectedRequests()"
    >
      <RequestPanelItem
        v-for="(request, i) in requests"
        :key="i"
        :request="request"
      />
    </PanelList>

    <RequestModal />
  </div>
</template>

<script>
import PanelList from "@/components/general/panel-list";
import RequestPanelItem from "@/components/panel-items/request";
import RequestsMixin from '@/mixins/requests'
import RequestModal from '@/components/modals/request'

export default {
  mixins: [RequestsMixin],

  mounted () {
    this.clearRequestsCounter()
  },
  
  computed: {
    isAllSelected() {
      return this.requests.length == this.selectedRequests.length;
    },
  },

  components: {
    PanelList,
    RequestPanelItem,
    RequestModal
  },
};
</script>