<template>
  <span>
    <ArabicNumber :number="finalAmount" /> <span v-if="withCurrency">ريال</span>
  </span>
</template>

<script>
export default {
  methods: {
    round(num) {
      return Math.round((num + Number.EPSILON) * 100) / 100;
    },
  },

  computed: {
    finalAmount() {
      let amount = parseFloat(this.amount);
      let digits = amount - Math.floor(amount);
      return digits > 0 ? this.round(amount) : Math.floor(amount);
    },
  },

  props: {
    amount: {
      type: Number,
    },
    withCurrency: {
      type: Boolean,
      default: true
    }
  },
};
</script>