<template>
  <div>
      <div class="text-right">
          <button class="btn btn-primary btn-sm" @click="addBillTap">
              <fa icon="plus" class="ml-2" />
              <span>إضافة فاتورة</span>
          </button>
      </div>
    <PanelList
      class="mt-3"
      :items="bills"
      title="الفواتير"
      :isAllSelected="isAllSelected"
      @toggle-items-selection="toggleBillsSelection()"
      @delete-selected="deleteSelectedBills()"
    >
      <BillPanelItem
        v-for="(bill, i) in bills"
        :key="i"
        :bill="bill"
      />
    </PanelList>

    <BillModal />
  </div>
</template>

<script>
import PanelList from "@/components/general/panel-list";
import BillPanelItem from "@/components/panel-items/bill";
import BillModal from "@/components/modals/bill";
import BillsMixin from '@/mixins/bills'
import PropertiesMixin from '@/mixins/properties'

export default {
  mixins: [BillsMixin, PropertiesMixin],

  mounted () {
    this.clearBillsCounter()
  },

  methods: {
    addBillTap () {
      this.selectBill(null)
      this.$bvModal.show('bill-modal')
    },
  },

  computed: {
    isAllSelected () {
      return this.bills.length == this.selectedBills.length
    }
  },

  components: {
    PanelList,
    BillPanelItem,
    BillModal,
  },
};
</script>