<template>
  <b-modal id="discount-modal">
    <template v-slot:modal-header="">
      <h5>الخصم</h5>
    </template>

    <template v-slot:default="">
      <div class="form-group row">
        <label class="col-sm-4 col-form-label text-muted text-right"
          >الجهة المقدمة</label
        >
        <div class="col-sm-8">
          <input type="text" class="form-control" v-model="discount.offerer" />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-4 col-form-label text-muted text-right"
          >الخصم</label
        >
        <div class="col-sm-8">
          <input type="number" class="form-control" v-model="discount.rate" />
        </div>
      </div>

      <GmapMap
        ref="map"
        :center="{ lat: discount.lat, lng: discount.lng }"
        :zoom="14"
        style="width: 100%; height: 300px"
        @click="updateMarker"
      >
        <GmapMarker
          :position="{ lat: discount.lat, lng: discount.lng }"
          :clickable="false"
          :draggable="false"
          @click="center = { lat: discount.lat, lng: discount.lng }"
        />
      </GmapMap>

      <div
        class="pointer d-inline-block mt-3"
        @click="$refs.discountImage.click()"
      >
        <div class="m-auto">
          <img
            ref="discountPreview"
            class="id-card-preview m-auto"
            :src="getImage"
            alt=""
          />
        </div>
        <span class="text-muted small">تحديث الصورة</span>
        <input
          ref="discountImage"
          type="file"
          style="display: none"
          @change="updateImage"
          accept="image/*"
        />
      </div>
    </template>

    <template v-slot:modal-footer="">
      <button type="submit" class="btn btn-primary" @click="submit">حفظ</button>
      <a href="#" class="text-muted" @click="$bvModal.hide('discount-modal')"
        >إغلاق</a
      >
    </template>
  </b-modal>
</template>

<script>
import DiscountsMixin from "@/mixins/discounts";

export default {
  mixins: [DiscountsMixin],

  data() {
    return {
      discount: {
        offerer: null,
        rate: null,
        lat: 24.627463,
        lng: 46.691385,
        image: {
          link: null,
        },
      },
    };
  },

  mounted() {
    this.buildDiscount();
  },

  methods: {
    buildDiscount() {
      if (this.selectedDiscount) {
        this.discount.offerer = this.selectedDiscount.offerer;
        this.discount.rate = this.selectedDiscount.rate;
        this.discount.lat = this.selectedDiscount.lat;
        this.discount.lng = this.selectedDiscount.lng;

        if (this.selectedDiscount.image) {
          this.discount.image.link = this.selectedDiscount.image.link;
        }
      } else {
        this.discount = {
          offerer: null,
          rate: null,
          lat: 24.627463,
          lng: 46.691385,
          image: {
            link: null
          }
        };
      }
    },

    submit() {
      this.saveDiscount(this.discount).then((res) => {
        if (res.success) {
          if (this.$refs.discountImage.files[0]) {
            this.saveDiscountImage(this.$refs.discountImage.files[0]).then(
              (res2) => {
                if (res2.success) this.$bvModal.hide("discount-modal");
              }
            );
          } else {
            this.showSwal(res);
            this.$bvModal.hide("discount-modal");
          }
        } else {
          this.showSwal(res);
        }
      });
    },

    updateImage(e) {
      this.$refs.discountPreview.src = URL.createObjectURL(e.target.files[0]);
    },

    updateMarker(e) {
      this.discount.lat = e.latLng.lat();
      this.discount.lng = e.latLng.lng();
    },
  },

  computed: {
    getImage () {
      return this.discount.image ? this.discount.image.link : require('@/assets/images/image-placeholder.png')
    }
  },

  watch: {
    selectedDiscount() {
      this.buildDiscount();
    },
  },
};
</script>