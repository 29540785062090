import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
    methods: {
        ...mapActions({
            toggleRequestsSelection: 'requests/toggleRequestsSelection',
            toggleRequestSelection: 'requests/toggleRequestSelection',
            deleteSelectedRequests: 'requests/deleteSelectedRequests',
            clearRequestsCounter: 'requests/clearRequestsCounter',
            updateRequest: 'requests/updateRequest',
            deleteImage: 'requests/deleteImage',
        }),

        ...mapMutations({
            selectRequest: 'requests/SET_SELECTED_REQUEST'
        })
    },

    computed: {
        ...mapGetters({
            requests: 'requests/requests',
            selectedRequest: 'requests/selectedRequest',
            selectedRequests: 'requests/selectedRequests'
        })
    }
}