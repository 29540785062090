<template>
  <div class="mb-5">
    <div v-if="selectedProperty" class="text-right mb-3">
      <button
        class="btn btn-primary btn-sm mb-3"
        @click="$bvModal.show('add-renting-modal')"
      >
        <fa icon="plus" class="ml-0 ml-sm-2" />
        <span class="d-none d-sm-inline">إضافة مستأجر</span>
      </button>

      <button
        class="btn btn-danger btn-sm mb-3 float-left"
        @click="deletePropertyTap"
      >
        <fa icon="trash" class="ml-0 ml-sm-2" />
        <span class="d-none d-sm-inline">حذف العقار</span>
      </button>

      <div v-if="selectedProperty.client_id" class="panel mb-5">
        <div class="header">
          <span>المستأجر</span>
        </div>
        <div class="body">
          <div class="container p-4">
            <div class="row">
              <div
                class="col-12 col-md-6 text-center text-md-right mb-3 mb-md-0"
              >
                <router-link
                  :to="{
                    name: 'clients.show',
                    params: { clientNumber: selectedProperty.client.number },
                  }"
                >
                  {{ selectedProperty.client.name }}
                </router-link>
              </div>
              <div class="col-12 col-md-6 text-center text-md-left">
                <button
                  class="btn btn-sm btn-warning ml-2"
                  @click="showAgreementTap"
                >
                  <fa icon="file-contract" class="ml-2" />
                  <span>عرض العقد</span>
                </button>

                <button
                  class="btn btn-sm btn-danger"
                  @click="deleteAgreementTap"
                >
                  <fa icon="trash" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="panel">
      <div class="header">
        <span>بيانات العقار</span>
        <div class="float-left ml-3">
          <b-form-checkbox
            class="d-inline pl-0"
            v-model="property.isPublic"
            :value="true"
          >
          إظهار العقار
          </b-form-checkbox>
        </div>
      </div>
      <div class="body">
        <div class="container">
          <div class="m-auto w-100 pb-5">
            <div class="form-group text-right">
              <label class="small">العنوان:</label>
              <input
                class="form-control"
                placeholder="العنوان"
                v-model="property.title"
                type="text"
              />
              <div v-if="!property.title" class="small text-red">
                الإدخال مطلوب.
              </div>
            </div>

            <div class="form-group text-right">
              <label class="small">الرقم:</label>
              <input
                class="form-control"
                placeholder="الرقم"
                v-model="property.number"
                type="number"
              />
              <div v-if="!property.number" class="small text-red">
                الإدخال مطلوب.
              </div>
            </div>

            <div class="form-group text-right">
              <label class="small">القسم:</label>
              <select class="form-control" v-model="property.category_id">
                <option
                  v-for="(cat, i) in categories"
                  :key="i"
                  :value="cat.id"
                  :selected="cat.id == property.category_id"
                >
                  {{ cat.name }}
                </option>
              </select>
            </div>

            <div class="form-group text-right">
              <label class="small">عدد الغرف:</label>
              <input
                class="form-control"
                placeholder="عدد الغرف"
                v-model="property.roomsCount"
                type="number"
              />
              <div v-if="!property.roomsCount" class="small text-red">
                الإدخال مطلوب.
              </div>
            </div>

            <div class="form-group text-right">
              <label class="small">الصالة:</label>
              <select class="form-control" v-model="property.isWithHall">
                <option :value="true">متوفرة</option>
                <option :value="false">غير متوفرة</option>
              </select>
            </div>

            <div class="form-group text-right">
              <label class="small">عدد دورات المياه:</label>
              <input
                class="form-control"
                placeholder="عدد دورات المياه"
                v-model="property.bathsCount"
                type="number"
              />
              <div v-if="!property.bathsCount" class="small text-red">
                الإدخال مطلوب.
              </div>
            </div>

            <div class="form-group text-right">
              <label class="small">نظام المطبخ:</label>
              <select class="form-control" v-model="property.isAmericanKitchen">
                <option :value="true">أمريكي</option>
                <option :value="false">مستقل</option>
              </select>
            </div>

            <div class="form-group text-right">
              <label class="small">الأثاث:</label>
              <select class="form-control" v-model="property.isFurnished">
                <option :value="true">متوفر</option>
                <option :value="false">غير متوفر</option>
              </select>
            </div>

            <div class="form-group text-right">
              <label class="small">السعر:</label>
              <input
                class="form-control"
                placeholder="السعر"
                v-model="property.amount"
                type="number"
              />
              <div v-if="!property.amount" class="small text-red">
                الإدخال مطلوب.
              </div>
            </div>

            <div class="form-group text-right">
              <label class="small">الوصف:</label>
              <textarea
                class="form-control"
                placeholder="الوصف"
                v-model="property.description"
              ></textarea>
            </div>

            <div class="form-group text-right">
              <label class="small">رابط الفيديو:</label>
              <input
                class="form-control"
                placeholder="انسخ رابط اليوتيوب من المتصفح كما هو"
                v-model="property.videoLink"
                type="url"
              />
            </div>

            <div class="map-wrapper mt-5">
              <GmapMap
                ref="map"
                :center="{ lat: property.lat, lng: property.lng }"
                :zoom="14"
                style="width: 100%; height: 300px"
                @click="updateMarker"
              >
                <GmapMarker
                  :position="{ lat: property.lat, lng: property.lng }"
                  :clickable="false"
                  :draggable="false"
                  @click="center = { lat: property.lat, lng: property.lng }"
                />
              </GmapMap>
            </div>

            <div class="mt-5">
              <ImagesWrapper
                :images="allImages"
                @images-changed="updateImages($event)"
                @delete-image="deleteImageTap($event)"
              />
            </div>

            <button
              class="btn btn-block btn-primary btn-lg mt-4"
              @click="submit"
            >
              <fa icon="save" class="ml-2" />
              <span>حفظ</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="
        selectedProperty &&
        property.id == selectedProperty.id &&
        showMaintenances
      "
    >
      <div class="text-right mt-5 mb-3">
        <button class="btn btn-primary btn-sm" @click="addMaintenanceTap">
          <fa icon="plus" class="ml-2" />
          <span>إضافة احتياج صيانة</span>
        </button>
      </div>

      <PanelList
        :items="property.maintenances"
        title="الصيانة"
        :isAllSelected="isAllSelected"
        @toggle-items-selection="toggleMaintenanceSelection()"
      >
        <MaintenancePanelItem
          v-for="(maintenance, i) in property.maintenances"
          :key="i"
          :maintenance="maintenance"
        />
      </PanelList>
    </div>

    <MaintenanceModal />
    <AddRentingModal />
    <AgreementModal />
  </div>
</template>

<script>
import PorpertiesMixin from "@/mixins/properties";
import CategoriesMixin from "@/mixins/categories";
import MaintenancesMixin from "@/mixins/maintenances";
import AgreementsMixin from "@/mixins/agreements";
import PanelList from "@/components/general/panel-list";
import MaintenancePanelItem from "@/components/panel-items/maintenance";
import MaintenanceModal from "@/components/modals/maintenance";
import AddRentingModal from "@/components/modals/add-renting";
import AgreementModal from "@/components/modals/agreement";

export default {
  mixins: [
    PorpertiesMixin,
    CategoriesMixin,
    MaintenancesMixin,
    AgreementsMixin,
  ],

  data() {
    return {
      property: {
        number: null,
        category_id: 1,
        roomsCount: null,
        isWithHall: true,
        isAmericanKitchen: false,
        description: null,
        lat: 24.627463,
        lng: 46.691385,
        bathsCount: null,
        isFurnished: true,
        images: [],
      },

      newImages: [],

      showMaintenances: true,
    };
  },

  mounted() {
    if (
      this.$route.params.propertyNumber !== "create" &&
      !this.selectedProperty
    ) {
      this.$router.push({ name: "properties.index" });
    }
    if (this.$route.params.propertyNumber == "create") {
      this.selectProperty(null);
    } else {
      this.property = this.selectedProperty;
    }
  },

  methods: {
    submit() {
      if (this.selectedProperty) {
        this.updateProperty(this.property).then((res) => this.saveImages(res));
      } else {
        this.saveProperty(this.property).then((res) => this.saveImages(res));
      }
    },

    saveImages(res) {
      if (res.success) {
        this.uploadImages(this.newImages).then((res) => {
          if (res.success) {
            // this.showSwal(res);
            this.$router.push({ name: "properties.index" });
          }
        });
      }
    },

    updateMarker(e) {
      this.property.lat = e.latLng.lat();
      this.property.lng = e.latLng.lng();
    },

    updateImages(files) {
      files.forEach((file) => {
        this.newImages.push(file);
      });
    },

    deleteImageTap(image) {
      // console.log(image)
      let foundImage = this.property.images.find((img) => img == image);

      if (foundImage) {
        this.deleteImage(image).then((res) => {
          if (res.success) {
            this.property.images.splice(this.property.images.indexOf(image), 1);
          }
        });
      } else {
        foundImage = this.newImages.find((img) => img == image);
        this.newImages.splice(this.newImages.indexOf(image), 1);
      }
    },

    addMaintenanceTap() {
      this.selectMaintenance(null);
      this.$bvModal.show("maintenance-modal");
    },

    deleteAgreementTap() {
      this.deleteAgreement().then(() => {
        // this.showSwal(res)
      });
    },

    showAgreementTap() {
      console.log("selectedProperty: ", this.selectedProperty);
      this.selectAgreement(this.selectedProperty.id);
      this.$bvModal.show("agreement-modal");
    },

    deletePropertyTap() {
      this.confirmSwal().then((res) => {
        if (res) {
          this.deleteProperty(this.property).then((res) => {
            if (res.success) {
              this.$router.push({ name: "properties.index" });
            }
          });
        }
      });
    },
  },

  computed: {
    allImages() {
      this.newImages.forEach((newImage) => {
        newImage.src = URL.createObjectURL(newImage);
      });
      return this.property.images.concat(this.newImages);
    },
  },

  components: {
    PanelList,
    MaintenancePanelItem,
    MaintenanceModal,
    AddRentingModal,
    AgreementModal,
  },

  watch: {
    "selectedProeprty.maintenances": () => {
      this.showMaintenances = false;
      this.$nexTick(() => {
        this.showMaintenances = true;
      });
    },
  },
};
</script>