import axios from '@/axios'

export default {
    namespaced: true,

    state: {
        ads: [],
        selectedAd: null
    },

    getters: {
        ads: state => state.ads,
        selectedAd: state => state.selectedAd,
        selectedAds: state => {
            return state.ads.filter(m => m.selected)
        },
    },

    mutations: {
        SET_ADS: (state, payload) => state.ads = payload,
        SET_SELECTED_AD: (state, payload) => state.selectedAd = payload,
        SELECT_ALL: state => {
            state.ads.forEach(m => m.selected = true)
        },
        DESELECT_ALL: state => {
            state.ads.forEach(m => m.selected = false)
        },
        SELECT_AD: (state, payload) => {
            state.ads[state.ads.indexOf(payload)].selected = true
        },

        DESELECT_AD: (state, payload) => {
            state.ads[state.ads.indexOf(payload)].selected = false
        },
    },

    actions: {
        async fetchAds({ commit }) {
            let { data } = await axios.get('admin/ads')

            commit('SET_ADS', data.ads)
        },

        async updateAd({ dispatch, getters }, payload) {
            let { data } = await axios.put(`admin/ads/${getters.selectedAd.id}`, payload)

            if (data.success) {
                dispatch('fetchAds')
            }

            return data
        },

        async saveAd({ dispatch, commit, getters }, payload) {
            let { data } = await axios.post(`admin/ads`, payload)

            if (data.success) {
                return await dispatch('fetchAds').then(() => {
                    let newAd = getters.ads.find(p => p.id == data.ad.id)
                    commit('SELECT_AD', newAd)
                    return data
                })
            }

            return data
        },

        async deleteImage(_, image) {
            let { data } = await axios.delete(`admin/images/${image.id}`)

            return data
        },

        async uploadImages({ dispatch, getters }, images) {
            if (!images) return { success: true, message: 'تم الحفظ بنجاح' }
            let formData = new FormData()
            for (let i = 0; i < images.length; i++) {
                formData.append('images[' + i + ']', images[i])
            }
            formData.append('ad_id', getters.selectedAd.id)
            formData.append('isFront', 0)
            let { data } = await axios.post('admin/images', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            })

            if (data.success) {
                dispatch('fetchAds')
            }
            return data
        },

        async deleteSelectedAds({ getters, dispatch }) {
            let ids = []
            getters.selectedAds.forEach(doc => ids.push(doc.id))
            let { data } = await axios.delete('admin/ads', { params: { ids } })
            dispatch('fetchAds')
            return data
        },

        toggleAdsSelection({ commit, getters, state }) {
            if (getters.selectedAds.length < state.ads.length) {
                commit('SELECT_ALL')
            } else {
                commit('DESELECT_ALL')
            }
        },

        toggleAdSelection({ commit, state }, payload) {
            let currentState = state.ads[state.ads.indexOf(payload)].selected
            if (currentState) {
                commit('SELECT_AD', payload)
            } else {
                commit('DESELECT_AD', payload)
            }
        },
    }
}