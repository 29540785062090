import axios from '@/axios'

export default {
    namespaced: true,

    state: {
        discounts: [],
        selectedDiscount: null
    },

    getters: {
        discounts: state => state.discounts,
        selectedDiscount: state => state.selectedDiscount,
        selectedDiscounts: state => {
            return state.discounts.filter(d => d.selected)
        }
    },

    mutations: {
        SET_DISCOUNTS: (state, payload) => state.discounts = payload,
        SET_SELECTED_DISCOUNT: (state, payload) => state.selectedDiscount = payload,
        SELECT_ALL: state => {
            state.discounts.forEach(m => m.selected = true)
        },
        DESELECT_ALL: state => {
            state.discounts.forEach(m => m.selected = false)
        },
        SELECT_DISCOUNT: (state, payload) => {
            state.discounts[state.discounts.indexOf(payload)].selected = true
        },

        DESELECT_DISCOUNT: (state, payload) => {
            state.discounts[state.discounts.indexOf(payload)].selected = false
        },
    },

    actions: {
        async fetchDiscounts({ commit }) {
            let { data } = await axios.get('admin/discounts')
            data.discounts.forEach(m => m.selected = false)
            commit('SET_DISCOUNTS', data.discounts)

        },

        toggleDiscountsSelection({ commit, getters, state }) {
            if (getters.selectedDiscounts.length < state.discounts.length) {
                commit('SELECT_ALL')
            } else {
                commit('DESELECT_ALL')
            }
        },

        toggleDiscountSelection({ commit, state }, payload) {
            let currentState = state.discounts[state.discounts.indexOf(payload)].selected
            if (currentState) {
                commit('SELECT_DISCOUNT', payload)
            } else {
                commit('DESELECT_DISCOUNT', payload)
            }
        },

        async deleteSelectedDiscounts({ getters, dispatch }) {
            let ids = []
            getters.selectedDiscounts.forEach(doc => ids.push(doc.id))
            let { data } = await axios.delete('admin/discounts', { params: { ids } })
            dispatch('fetchDiscounts')
            return data
        },

        async saveDiscount({ commit, dispatch, getters }, payload) {
            let url = getters.selectedDiscount ? `admin/discounts/${getters.selectedDiscount.id}` : `admin/discounts`
            let { data } = await axios({
                method: getters.selectedDiscount ? 'put' : 'post',
                url,
                data: payload
            })

            if (data.success) {
                dispatch('fetchDiscounts')
                commit('SET_SELECTED_DISCOUNT', data.discount)
            }

            return data
        },

        async saveDiscountImage({ getters, dispatch }, image) {
            let formData = new FormData()
            formData.append('images[0]', image)
            formData.append('discount_id', getters.selectedDiscount.id)
            let { data } = await axios.post('admin/images', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            })

            if (data.success) {
                dispatch('fetchDiscounts')
            }

            return data
        },
    }
}