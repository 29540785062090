<template>
  <div class="container text-center p-3">
    <!-- <nav class="navbar navbar-expand-lg navbar-light px-0 text-center"> -->
      <router-link :to="{ name: 'guest.home' }" class="navbar-brand mx-0">
      </router-link>
      <!-- <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button> -->

      <!-- <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item d-block d-lg-none">
            <router-link class="nav-link" :to="{ name: 'home' }"
              >اطبع الآن</router-link
            >
          </li>

          <li class="nav-item">
            <a :href="`${VUE_APP_AGENT_URL}/login`" class="nav-link"
              >تسجيل مقدم الخدمة</a
            >
          </li>

          <li class="d-none d-lg-block nav-item">
            <router-link class="btn btn-primary" :to="{ name: 'home' }"
              >اطبع الآن</router-link
            >
          </li>
        </ul>
      </div> -->
    <!-- </nav> -->
  </div>
</template>

<script>
export default {
}
</script>