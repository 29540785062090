<template>
    <div v-if="user && !user.email_verified_at" class="alert alert-warning" id="validation-message" style="position: relative; top: 60px;">
        <fa icon="exclamation" class="ml-4" />
        <span>يرجى التحقق من البريد الإلكتروني لتتمكن من الاستفادة من الموقع.</span>
    </div>
</template>

<script>
import AuthMixin from '@/mixins/auth'

export default {
    mixins: [AuthMixin]
}
</script>