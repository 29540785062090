<template>
  <div class="container pb-5">
      <div class="panel">
        <div class="header">
          <span>بيانات الإعلان</span>
        </div>
    <div class="m-auto w-100 pb-5">

        <div class="body p-4">
          <div class="form-group text-right">
            <label class="small">العنوان:</label>
            <input
              class="form-control"
              placeholder="العنوان"
              v-model="ad.title"
            />
            <div v-if="!ad.title" class="small text-red">الإدخال مطلوب.</div>
          </div>

          <div class="form-group text-right">
            <label class="small">الوصف:</label>
            <textarea
              class="form-control"
              placeholder="الوصف"
              v-model="ad.body"
            ></textarea>
            <div v-if="!ad.body" class="small text-red">الإدخال مطلوب.</div>
          </div>

          <div class="form-group text-right">
            <label class="small">رابط الفيديو:</label>
            <input
              class="form-control"
              placeholder="انسخ رابط اليوتيوب من المتصفح كما هو"
              v-model="ad.videoLink"
              type="url"
            />
          </div>

          <div class="map-wrapper mt-5">
              <GmapMap
                ref="map"
                :center="{ lat: ad.lat, lng: ad.lng }"
                :zoom="14"
                style="width: 100%; height: 300px"
                @click="updateMarker"
              >
                <GmapMarker
                  :position="{ lat: ad.lat, lng: ad.lng }"
                  :clickable="false"
                  :draggable="false"
                  @click="center = { lat: ad.lat, lng: ad.lng }"
                />
              </GmapMap>
            </div>

          <div class="mt-5">
            <ImagesWrapper
              :images="allImages"
              @images-changed="updateImages($event)"
              @delete-image="deleteImageTap($event)"
            />
          </div>

          <button class="btn btn-block btn-primary btn-lg mt-4" @click="submit">
            <fa icon="save" class="ml-2" />
            <span>حفظ</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdsMixin from "@/mixins/ads";

export default {
  mixins: [AdsMixin],

  data() {
    return {
      ad: {
        title: null,
        body: null,
        images: [],
        vidoeLink: null,
        lat: 24.627463,
        lng: 46.691385,
      },

      newImages: [],
    };
  },

  mounted() {
    if (this.$route.params.adNumber !== "create" && !this.selectedAd) {
      this.$router.push({ name: "ads.index" });
    }
    if (this.$route.params.adNumber == "create") {
      this.selectAd(null);
    } else {
      this.ad = this.selectedAd;
    }
  },

  methods: {
    submit() {
      if (this.selectedAd) {
        this.updateAd(this.ad).then((res) => this.saveImages(res));
      } else {
        this.saveAd(this.ad).then((res) => this.saveImages(res));
      }
    },

    saveImages(res) {
      if (res.success) {
        this.uploadImages(this.newImages).then((res) => {
          if (res.success) {
            // this.showSwal(res);
            this.$router.push({ name: "ads.index" });
          }
        });
      }
    },

    updateImages(files) {
      files.forEach((file) => {
        this.newImages.push(file);
      });
    },

    updateMarker(e) {
      this.ad.lat = e.latLng.lat();
      this.ad.lng = e.latLng.lng();
    },

    deleteImageTap(image) {
      // console.log(image)
      let foundImage = this.ad.images.find((img) => img == image);

      if (foundImage) {
        this.deleteImage(image).then((res) => {
          if (res.success) {
            this.ad.images.splice(this.ad.images.indexOf(image), 1);
          }
        });
      } else {
        foundImage = this.newImages.find((img) => img == image);
        this.newImages.splice(this.newImages.indexOf(image), 1);
      }
    },
  },

  computed: {
    allImages() {
      this.newImages.forEach((newImage) => {
        newImage.src = URL.createObjectURL(newImage);
      });
      return this.ad.images.concat(this.newImages);
    },
  },
};
</script>