import { mapActions, mapGetters } from 'vuex';

export default {
    methods: {
        ...mapActions({
            saveNotification: 'notifications/saveNotification'
        })
    },

    computed: {
        ...mapGetters({

        })
    }
}