<template>
  <li>
    <a href="#!" @click.prevent="showAdTap">
      <div class="row">
        <div class="col-12 col-sm-6 col-md-8 panel-info text-center text-sm-right">
            <div @click.stop class="d-inline">
            <b-form-checkbox
              class="d-inline pl-0"
              v-model="ad.selected"
              :value="true"
              @change.native="toggleAdSelection(ad)"
            >
            </b-form-checkbox>
          </div>
          {{ ad.title }}
        </div>

        <div class="col-12 col-sm-6 col-md-4 panel-info text-center">
          {{ getDate(ad.updated_at) }}
        </div>
      </div>

      <div
        v-if="showDetails"
        @click.stop="showDetails = false"
        class="container doc-row-details"
      >
        <div class="row">
          <div class="col-12 text-justify">
            {{ ad.body }}
          </div>
        </div>
      </div>
    </a>
  </li>
</template>

<script>
import AdsMixin from '@/mixins/ads'

export default {
  mixins: [AdsMixin],
  
  data() {
    return {
      showDetails: false,
    };
  },

  methods: {
    showAdTap () {
      this.selectAd(this.ad)
      this.$router.push({ name: 'ads.show', params: { adNumber: this.ad.number } })
      console.log('ad selected ', this.selectedAd.number)
    }
  },

  props: ["ad"],
};
</script>