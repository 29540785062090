<template>
  <div>
    <div class="flex-wrapper" style="min-height: 500px">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div class="card">
              <div class="card-header">إعادة تعيين كلمة المرور</div>

              <div class="card-body">
                <!-- @if (session('status'))
                <div class="alert alert-success" role="alert">
                  {{ session("status") }}
                </div>
                @endif -->

                <form method="POST">
                  <div class="form-group row">
                    <label
                      for="email"
                      class="col-md-4 col-form-label text-md-right"
                      >البريد الإلكتروني</label
                    >

                    <div class="col-md-6">
                      <input
                        id="email"
                        type="email"
                        class="form-control"
                        :class="{ 'is-invalid': emailError }"
                        name="email"
                        v-model="form.email"
                        required
                        autocomplete="email"
                        autofocus
                      />

                      <!-- @error('email')
                      <span class="invalid-feedback" role="alert">
                        <strong>{{ $message }}</strong>
                      </span>
                      @enderror -->
                    </div>
                  </div>

                  <div class="form-group row mb-0">
                    <div class="col-md-6 offset-md-4">
                      <button type="button" @click="submit" class="btn btn-primary">
                        إرسال رابط إعادة التعيين
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthMixin from '@/mixins/auth'

export default {
    mixins: [AuthMixin],

    data () {
        return {
            form: {
                email: null
            },
            emailError: false
        }
    },

    methods: {
        submit () {
            this.sendPasswordResetLink(this.form).then(() => {
                // this.showSwal(res)
            })
        }
    }
};
</script>