import axios from '@/axios'
// import Echo from 'laravel-echo';
// Vue.prototype.Echo = Echo
// window.Pusher = require('pusher-js');

export default {
    namespaced: true,

    state: {
        token: null,
        user: null,
    },

    getters: {
        loggedIn(state) {
            return state.user && state.token
        },

        user(state) {
            return state.user
        },
    },

    mutations: {
        SET_TOKEN(state, token) {
            state.token = token
        },

        SET_USER(state, user) {
            state.user = user
        },
    },

    actions: {
        async register({ dispatch }, payload) {
            payload.role = 'user'
            let { data } = await axios.post('register', payload)
            if (data.success) {
                return dispatch('attempt', data.access_token)
            }
        },

        async login({ dispatch }, credentials) {
            credentials.role = 'admin'
            let { data } = await axios.post('login', credentials)

            return await dispatch('attempt', data.access_token)
        },

        async attempt({ commit, state, dispatch }, token) {
            if (token) {
                commit('SET_TOKEN', token)
            }

            if (!state.token) {
                return false
            }

            try {
                let { data } = await axios.get('auth/me', {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                })

                if (data.user.role !== 'admin') {
                    dispatch('clearAuth')
                    return false
                }

                commit('SET_USER', data.user)

                dispatch('init', {}, { root: true })

                // dispatch('connectToChannels')
                // dispatch('handleUserInit')

                return true
            } catch (e) {
                dispatch('clearAuth')
                return false
            }
        },

        logout({ dispatch }) {
            return axios.post('auth/logout').then(() => {
                dispatch('clearAuth')
            })
        },

        clearAuth({ commit }) {
            commit('SET_TOKEN', null)
            commit('SET_USER', null)
        },

        async verifyEmail(_, payload) {
            let { data } = await axios.post('verifyEmail', payload)
            return data
        },

        async sendPasswordResetLink(_, payload) {
            let { data } = await axios.post('sendPasswordResetLink', payload)
            return data
        },

        async verifyPasswordResetToken(_, payload) {
            let { data } = await axios.post('verifyPasswordResetToken', payload)
            return data
        },

        async resetPassword(_, payload) {
            let { data } = await axios.post('resetPassword', payload)
            return data
        },

        async updateAdminData({ state }) {
            let { data } = await axios.post(`admin/updateAdminData`, state.user)

            return data
        }

        // connectToChannels({ state }) {
        //     window.Echo = new Echo({
        //         broadcaster: 'pusher',
        //         key: process.env.VUE_APP_PUSHER_APP_KEY,
        //         cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
        //         // encrypted: true,
        //         // wsHost: '127.0.0.1',
        //         authEndpoint: `${process.env.VUE_APP_API_URL}/api/broadcasting/auth`,
        //         // authEndpoint: 'http://localhost:8000/broadcasting/auth',
        //         wsHost: `${process.env.VUE_APP_WSS_HOST}`,
        //         wsPort: 6001,
        //         wssPort: 6001,
        //         disableStats: true,
        //         forceTLS: false,
        //         encrypted: false,
        //         enabledTransports: ['ws', 'wss'], // <- added this param
        //         auth: {
        //             headers: {
        //                 Authorization: 'Bearer ' + state.token
        //             }
        //         }
        //         // enabledTransports: ['ws', 'wss'] // <- added this param
        //     });
        // },

        // handleUserInit({ dispatch }) {
        //     dispatch('getConversations')
        // },

        // getConversations({ commit }) {
        //     axios.get(`conversations`).then((res) => {
        //         commit('SET_CONVERSATIONS', res.data.conversations)
        //         let unreadMessagesCount = 0
        //         res.data.conversations.forEach(c => {
        //             unreadMessagesCount += c.messages_count
        //         })
        //         console.log(res.data.conversations)
        //         if (unreadMessagesCount > 0) {
        //             commit('INCREMENT_MESSAGES_COUNTER', unreadMessagesCount)
        //         }
        //         if (res.data.notificationsCount > 0) {
        //             commit('INCREMENT_NOTIFICATIONS_COUNTER', res.data.notificationsCount)
        //         }
        //     })
        // },
    },

    modules: {}
}