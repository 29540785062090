<template>
  <li>
    <a href="#!" @click.prevent="showAgentTap">
      <div class="row">
        <div class="col-12 col-sm-6 col-md-2 panel-info text-center text-sm-right">
          <div @click.stop class="d-inline">
            <b-form-checkbox
              class="d-inline pl-0"
              v-model="agent.selected"
              :value="true"
              @change.native="toggleAgentSelection(agent)"
            >
            </b-form-checkbox>
          </div>
          {{ agent.name }}
        </div>

        <div class="col-12 col-sm-6 col-md-2 panel-info text-center">
            <!-- {{ agent.email }} -->
        </div>

        <div class="col-12 col-sm-6 col-md-2 panel-info text-center">
            {{ agent.mobile }}
        </div>

        <div class="col-12 col-sm-6 col-md-2 panel-info text-left">
        </div>

        <div class="col-12 col-sm-6 col-md-2 panel-info text-center">
            <button class="btn btn-sm btn-info" @click.stop="addNotificationTap">
              <fa icon="bell" />
            </button>
        </div>
      </div>
    </a>

    
  </li>
</template>

<script>
import AgentsMixin from '@/mixins/agents'

export default {
  mixins: [AgentsMixin],

  methods: {
    showAgentTap () {
      this.selectAgent(this.agent)
      this.$bvModal.show('agent-modal')
    },

    addNotificationTap() {
      this.selectAgent(this.agent)
      this.$bvModal.show('notification-modal')
    }
  },

  props: ["agent"],
};
</script>