<template>
  <div id="header-wrapper">
    <div id="header-side-wrapper">
      <div id="logo-wrapper">
        <!-- <img src="{{ asset('img/logo/logo.png') }}" width="106px" height="40px"> -->
      </div>
    </div>

    <div id="header-actions-wrapper" class="">
      <div id="btn-side-menu-toggle" @click="toggleSideMenu">
        <fa icon="bars" class="fa-lg" />
      </div>

      <div id="header-side-actions">
        <!-- <div>
  <b-dropdown id="dropdown-1" text="Dropdown Button" class="m-md-2">
    <b-dropdown-item>First Action</b-dropdown-item>
    <b-dropdown-item>Second Action</b-dropdown-item>
    <b-dropdown-item>Third Action</b-dropdown-item>
    <b-dropdown-divider></b-dropdown-divider>
    <b-dropdown-item active>Active action</b-dropdown-item>
    <b-dropdown-item disabled>Disabled action</b-dropdown-item>
  </b-dropdown>
</div> -->
        <!-- <div class="dropdown">
          <div
            id="btn-user-actions"
            class="dropdown-toggle"
            data-toggle="dropdown"
          >
            <fa :icon="['far', 'user']" />
          </div>
          <div id="" class="dropdown-menu">
            <router-link class="dropdown-item" :to="{ name: 'editProfile' }">
              <fa icon="user-cog" fixed-width />
              <span>إعدادات الحساب</span>
            </router-link>
            <router-link class="dropdown-item" :to="{ name: 'orders.index' }">
              <fa icon="shopping-bag" fixed-width />
              <span>طلباتي</span>
            </router-link>
            <router-link class="dropdown-item" :to="{ name: 'docs.index' }">
              <fa icon="copy" fixed-width />
              <span>ملفاتي</span>
            </router-link>
            <router-link class="dropdown-item" :to="{ name: 'wallet' }">
              <fa icon="wallet" fixed-width />
              <span>محفظتي</span>
            </router-link>
            <div class="dropdown-divider"></div>
            <form
              class="dropdown-item"
              method="post"
            >
              <button
                type="button"
                style="background-color: transparent; border: none"
                @click="logoutTap"
              >
                <fa icon="sign-out-alt" fixed-width />
                <span>تسجيل الخروج</span>
              </button>
            </form>
          </div>
        </div> -->

        <!-- <a
          href="{{ LaravelLocalization::getLocalizedURL(LaravelLocalization::getCurrentLocale() == 'ar' ? 'en' : 'ar') }}"
          class="btn-lang-switch"
        >
          {{ LaravelLocalization::getCurrentLocale() == "ar" ? "En" : "ع" }}
        </a> -->
      </div>
    </div>
  </div>
</template>

<script>
import AuthMixin from '@/mixins/auth'
import GeneralMixin from '@/mixins/general'

export default {
  mixins: [AuthMixin, GeneralMixin],
  
  methods: {
    logoutTap () {
      this.logout().then(() => {
        this.$router.push({ name: 'login' })
      })
    }
  }
}
</script>